<template>
  <div>
    <bfc-bar></bfc-bar>
    <inspector-menu></inspector-menu>
    
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6" sm="6">
            <v-text-field
              label="Doctor's name"
              v-model="filterName"
              hint="Doctor's name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-data-table
              :headers="headers"
              :items="casesTable"
              :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, -1] }"
              v-if="casesTable.length > 0"
            >
              <template v-slot:item.caseNo="{ item }">
                <!--v-badge color="red" v-if="item.isOneHr">
                  <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
                </v-badge>
                <v-badge color="orange" v-if="item.isOneDay">
                  <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
                </v-badge>
                <v-badge value="" v-if="!item.isOneDay && !item.isOneHr">
                  <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
                </v-badge-->
                <a @click="view(item.caseNo)">{{ item.caseNo }}</a>
              </template>

              <template v-slot:item.status="{ item }">
              <span v-if="item.inspectorCompleted.approvedDate !== undefined">
                Approved
              </span>
              </template>
              <template v-slot:item.actions="{ item }">
                
                <v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openFeedback(item.caseNo, item.id, item,'inspector')"
                  @closeFeedback="closeFeedback"
                  v-if="item.inspector != '' && item.inspector !== undefined "
                >
                  <v-badge color="orange" v-if="isnewInspectorFeedback(item)"> Feedback </v-badge>
                  <v-badge v-else value=""> Feedback </v-badge>

                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  dark
                  @click="openInspectorCaseApprove(item)"
 
                  v-if=" item.inspectorCompleted.approvedDate === undefined  "
                >
                 
                  <v-badge  value="">  Approve case</v-badge>

                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--feedback-app
      v-model="feedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="feedbackDialog"
      @closeFeedback="closeFeedback"
      useBy="LAB"
      v-if="feedbackCaseId"
    ></feedback-app-->
    <feedback-app
      v-model="inspectorFeedbackDialog"
      v-bind:caseNo="feedbackCaseNo"
      v-bind:caseId="feedbackCaseId"
      v-bind:custId="custId"
      v-bind:dialog="inspectorFeedbackDialog"
      useBy="INSPECTOR"
      @closeFeedback="closeFeedback"
      v-if="feedbackCaseId"
    ></feedback-app>
    <v-dialog
        v-model="dialogApprovedInspectorCase"
        @close="close()"
        max-width="600px"
        app
      >
        <v-card>
          <v-card-title class="headline"
            >Approve case {{approvedInspectorCase.caseNo}}  ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              @click="dialogApprovedInspectorCase = !dialogApprovedInspectorCase"
              >Cancel</v-btn
            >
            <v-btn color="success" @click="dialogApprovedInspectorCaseConfirm">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import SystemBar from "../../library/system-bar/system-bar";
import InspectorMenu from "../menubar/menubar";
import feedBack from "../../library/inspector-feedback/feedback";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    "bfc-bar": SystemBar,
    "inspector-menu": InspectorMenu,
    "feedback-app": feedBack,
  },
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      feedbackCaseNo: "",
      feedbackDialog: false,
      feedbackCaseId: "",
      commission:0,
      inspectorFeedbackDialog:false,
      dialogApprovedInspectorCase: false,
      approvedInspectorCase:{},
      //defaultPrice: 4000,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      shippingInformation: {
        shippingName: "-",
        trackingNo: "-",
        shippingDate: "-",
      },
      inspector: {},
      filterStatus: "",
      countInterval: null,
      toggle_exclusive: 0,
      filterName: "",
      cases: [],
      casesTable: [],
      dialog: [],
      headers: [
        {
          text: "Updated",
          value: "updatedAt",
        },
        {
          text: "Case No.",
          value: "caseNo",
        },
        {
          text: "Doctor",
          value: "doctor_name",
        },
        {
          text: "Patient",
          value: "patient_name",
        },

        {
          text: "Case Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      newCase: "BFC/newCaseList",
      allCases: "BFC/allCases",
      listSubmitted: "BFC/listSubmitted",
      listApproved: "BFC/listApproved",
      listByStatus: "BFC/listByStatus",
    }),
    listNewCase() {
      return this.newCase;
    },
  },
  beforeMount(){
    this.inspector = this.$cookies.get("Profile").inspector[0];
    console.log(this.inspector);  
  },
  beforeDestroy() {
    clearInterval(this.countInterval);
  },
  mounted() {
    //setInterval(this.init(), 15 * 60 * 1000);
    
    this.init();
    this._interval();
    this.getConfig({
      code: "Inspector",
      name: "Commission",
      
      //custId: sessionStorage.getItem("custId"),
    }).then((response) => {
      console.log("getConfig");
      console.log(response);
     
    });
    
  },
  methods: {
    ...mapActions({
      setInspectorCasesList: "BFC/setInspectorCasesList",
      setfilterCaseStatus: "BFC/setfilterCaseStatus",
    }),
    async getConfig(cfg) {
      //
      try {
        this.payload = {
          createBy: sessionStorage.getItem("email"),
          updateBy: sessionStorage.getItem("email"),
          custId: sessionStorage.getItem("custId"),

          /*code:"Point",
                    name:"Type",
                    */
          ...cfg,
          //custId: sessionStorage.getItem("custId"),
        };
        await axios
          .post(
            this.url + "/v1/admin/getConfigByName",
            this.payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            //console.log(response)
            //this.pointType = response.data.value;
            //return response.data;
            if(response.data.length > 0){
                this.commission = response.data[0].cfg_value
              }
          });
      } catch (err) {
        this.responseStatus = {
          status: "Error",
          code: 500,
          text: err,
        };
      }
    },
    openInspectorCaseApprove(item){
      
      this.approvedInspectorCase = {
        custId: this.custId,
        inspectorId: item.inspector,
        caseNo: item.caseNo,
        runNo: item.runNo,
        commission: this.commission,
        approvedDate: new Date()
      }
      this.dialogApprovedInspectorCase = true;
      console.log(this.approvedInspectorCase);
    },
    isnewFeedback(item){
      console.log(item.feedback.map(item =>{ return item.read }));
      if( item.feedback.length > 0  ){
          return (item.feedback.map(item =>{ return item.read }).indexOf("INSPECTOR") == -1);
        }else{
          return true;
        }
    },
    isnewInspectorFeedback(item){
      if( item.inspectorFeedback.length > 0  ){
          return (item.inspectorFeedback.map(item =>{ return item.read }).indexOf("INSPECTOR") == -1);
        }else{
          return true;
        }
    },
    async dialogApprovedInspectorCaseConfirm(){
      console.log(this.approvedInspectorCase);
     
      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/cases/completed",
            this.approvedInspectorCase, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              /*console.log(response.data)
              this.setInspectorList(response.data.data);
              console.log(this.listInspectorUser);
              this.inspector = this.listInspectorUser.map((item) => {
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.memberId.email,
                  fullname: item.memberId.firstname + " " + item.memberId.lastname,
                  status: item.status,
                  //totalCases: 0,
                  //lastPayment: "-",
                };
                //n++;
              });
              */
              this.init();

            } else {
              // show error
              console.log("error response");
            }

            this.dialogApprovedInspectorCase = false; 
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
        closeFeedback(val) {
      //console.log("close feedback");
      this.feedbackDialog = val;
      this.inspectorFeedbackDialog = val;
      this.feedbackCaseNo = "";
      this.feedbackCaseId = "";
      //console.log(this.feedbackDialog);
    },
    openFeedback(caseNo, caseId, item , dialog) {
      //console.log(caseNo + "  " + caseId);
      //console.log(this.feedbackDialog);
      this.feedbackCaseNo = caseNo;
      this.feedbackCaseId = caseId;
      if(dialog != 'inspector'){
        this.feedbackDialog = true;
        //Object.assign(item.feedback,{read:"ADMIN"});
        //console.log(item);
        item.feedback.push({read:"INSPECTOR"})
      }else{
        this.inspectorFeedbackDialog = true;
        //Object.assign(item.inspectorFeedback,{read:"ADMIN"});
        item.inspectorFeedback.push({read:"INSPECTOR"})
        }
    },

    view(caseNo) {
      this.$router.push("/inspector/view/" + caseNo);
    },
    _interval() {
      this.countInterval = setInterval(this.init, 600000);
    },
    async init() {
      console.log("initial data.....");
      let payload = {
              inspector: this.inspector._id
            }
      console.log(payload);

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/inspector/cases",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            // console.log(response.data);
            if (response.data.statusCode == 0) {
              //this.cases = response.data.data;
              this.cases = response.data.data //this.cases
                .filter((item) => {
                  let status = "Draft";
                  if (typeof item.caseStatus !== "undefined") {
                    status = item.caseStatus.stateStatus;
                  }
                  if (this.filterStatus == "All" || this.filterStatus == "") {
                    return status != "Draft";
                  } else {
                    return status == this.filterStatus;
                  }
                })
                .map((item) => {
                  //console.log(item.updatedAt.toString());
                  //console.log(item);
                  let oneHr = false;
                  let oneDay = false;

                  //let updatedAt = new Date(item.updatedAt);
                  let currentTime = new Date();
                  let diffTime = new Date(
                    moment(item.updatedAt, "YYYY-MM-DD HH:mm:ss")
                      .add(7, "hours")
                      .format("YYYY-MM-DD HH:mm:ss")
                  );
                  // console.log(diffTime.getTime());

                  // console.log(currentTime.getTime());
                  // console.log(
                  //   (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                  // );

                  if (
                    parseInt(
                      (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                    ) <= 6
                  ) {
                    oneHr = true;
                  }
                  if (
                    6 <
                      parseInt(
                        (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                      ) &&
                    parseInt(
                      (currentTime.getTime() - diffTime.getTime()) / (60 * 60 * 1000)
                    ) <= 24
                  ) {
                    oneDay = true;
                  }

                  let _package = {
                    text: "",
                    value: "",
                    price: 0,
                    packageStatus: "None",
                  };
                  let selectPackage = "";
                  if (typeof item.package !== "undefined") {
                    _package = item.package;
                  }

                  if (typeof _package.text !== "undefined") {
                    selectPackage = _package.text;
                  }

                  // if (typeof item.package.text !== "undefined") {
                  //   selectPackage = item.package.text;
                  // }
                  //console.log(_package);
                  let shippingInformation =
                    item.shippingInformation || this.shippingInformation;

                  let feedback = [];
                  //console.log(typeof item.feedback);
                  if (item.feedback === undefined) {
                    feedback = item.feedback;
                  }

                  let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
                  if (item.inspectorFeedback !== undefined) {
                    inspectorFeedback = item.inspectorFeedback;
                  }


                  let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }

                  return {
                    updatedAt: item.updatedAt.toString().substring(0, 10),
                    rawUpdatedAt: item.updatedAt,
                    id: item._id,
                    caseNo: item.caseNo,
                    sequence: item.sequence,
                    runNo: item.runNo,
                    doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
                    feedback: feedback,
                    patient_name: item.caseDetail.patient_name,
                    selectPackage: selectPackage,
                    status: item.caseStatus.stateStatus,
                    shippingInformation: shippingInformation,
                    localUpdatedAt: moment(item.updatedAt, "YYYY-MM-DD HH:mm:ss")
                      .add(7, "hours")
                      .format("YYYY-MM-DD HH:mm:ss"),
                    _package: _package,
                    isOneHr: oneHr,
                    isOneDay: oneDay,
                    inspectorFeedback: inspectorFeedback,
                    inspectorCompleted: inspectorCompleted,
                    inspector:item.inspector
                  };
                });
              this.casesTable = this.cases;
              //console.log(this.casesTable);
              this.setInspectorCasesList(this.casesTable);
            } else {
              // show error
              console.log("error response");
            }
          });
        console.log(this.casesTable);
      } catch (e) {
        console.log(e);
      }
    },

    prepareData() {
      this.casesTable = this.cases.map((item) => {
        //console.log(item.updatedAt.toString());
        //console.log(item);
        let _package = {
          text: "",
          value: "",
          price: 0,
          packageStatus: "None",
        };
        let selectPackage = "";
        if (typeof item.package !== "undefined") {
          _package = item.package;
        }

        if (typeof _package.text !== "undefined") {
          selectPackage = _package.text;
        }

        // if (typeof item.package.text !== "undefined") {
        //   selectPackage = item.package.text;
        // }
        let status = "Draft";
        if (typeof item.caseStatus !== "undefined") {
          status = item.caseStatus.stateStatus;
        }
        let shippingInformation = item.shippingInformation || this.shippingInformation;
        let feedback = [];
                  //console.log(typeof item.feedback);
                  if (item.feedback === undefined) {
                    feedback = item.feedback;
                  }

        let inspectorFeedback = [];
                  //console.log(typeof item.inspectorFeedback);
                  if (item.inspectorFeedback !== undefined) {
                    inspectorFeedback = item.inspectorFeedback;
                  }


                  let inspectorCompleted = {};
                  if (item.inspectorCompleted !== undefined)
 {
                    inspectorCompleted = item.inspectorCompleted;
                  }

        let oneHr = false;
        let oneDay = false;

        return {
          // updatedAt: item.updatedAt.toString().substring(0, 10),
          // id: item._id,
          // caseNo: item.caseNo,
          // sequence: item.sequence,
          // runNo: item.runNo,
          // doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
          // feedback: feedback,
          // selectPackage: selectPackage,
          // patient_name: item.caseDetail.patient_name,
          // shippingInformation: shippingInformation,
          // status: status,
          // _package: _package,
          updatedAt: item.updatedAt.toString().substring(0, 10),
          rawUpdatedAt: item.updatedAt,
          id: item._id,
          caseNo: item.caseNo,
          sequence: item.sequence,
          runNo: item.runNo,
          doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
          feedback: feedback,
          patient_name: item.caseDetail.patient_name,
          selectPackage: selectPackage,
          //status: item.caseStatus.stateStatus,
          status: status,
          shippingInformation: shippingInformation,
          localUpdatedAt: moment(item.updatedAt, "YYYY-MM-DD HH:mm:ss")
            .add(7, "hours")
            .format("YYYY-MM-DD HH:mm:ss"),
          _package: _package,
          isOneHr: oneHr,
          isOneDay: oneDay,
          inspectorFeedback: inspectorFeedback,
          inspectorCompleted: inspectorCompleted,
          inspector:item.inspector
        };
      });
    },


    caseStatusColor(val) {
      let bgColor = "primary";
      //console.log(typeof val.stateStatus);
      //let stateStatus =
      if (typeof val.stateStatus != "undefined") {
        switch (val.stateStatus) {
          case "Submitted":
            bgColor = "success";
            break;
          case "RecievedFeedback":
            bgColor = "warning";
            break;
          case "Approved":
            bgColor = "primary";
            break;
          default:
            //bgColor = "primary";
            break;
        }
      }
      return bgColor;
    },
    async showCase(stateStatus) {
      //console.log(stateStatus);
      this.filterStatus = stateStatus;
      switch (stateStatus) {
        // case "Submitted":
        //   //console.log("RecievedNewCase");
        //   //this.cases = this.listSubmitted;

        //   //this.setfilterCaseStatus("Submitted");
        //   //this.casesTable = this.listlistByStatus;
        //   this.casesTable = this.cases.filter((item) => {
        //     let status = "Draft";
        //     if (typeof item.caseStatus !== "undefined") {
        //       status = item.caseStatus.stateStatus;
        //     }
        //     return item.status == this.filterStatus;
        //     //return item.case;
        //   });
        //   //console.log(this.cases);
        //   break;
        // case "RecievedFeedback":
        //   this.setfilterCaseStatus("RecievedFeedback");
        //   break;
        // case "Approved":
        //   this.setfilterCaseStatus("Approved");
        //   //this.cases = this.listApproved;
        //   this.cases = this.listByStatus;
        //   break;
        // case "WaitingForApprove":
        //   this.setfilterCaseStatus("Waiting for approve");
        //   this.cases = this.listByStatus;
        //   break;
        case "Shipped":
          //console.log("shipped");
          
          this.casesTable = this.cases.filter((item) => {
            return item.shippingInformation.shippingName != "-";
          });
          break;
        /*case "All":
          //bgColor = "primary";

          //this.cases = this.allCases;
          clearInterval(this.countInterval);
          this._interval();
          /*
          this.casesTable = this.cases.filter((item) => {
            // let status = "Draft";
            // if (typeof item.status !== "undefined") {
            //   status = item.caseStatus.stateStatus;
            // }
            return item.status != "Draft";
            //return item.case;
          });
          
          break;
          */
        default:
          //bgColor = "primary";
          clearInterval(this.countInterval);
          this.init();
          this._interval();

          //this.cases = this.allCases;
          //this.casesTable = this.cases.filter((item) => {
            // let status = "Draft";
            // if (typeof item.status !== "undefined") {
            //   status = item.caseStatus.stateStatus;
            // }
            //return item.status == this.filterStatus;
            //return item.case;
          //});
          break;
      }
    },
    viewCase(caseNo) {
      this.$router.push("/inspector/case/" + caseNo);
    },
  },
  watch: {
    toggle_exclusive: {
      handler() {
        //console.log(val);
        console.log(this.toggle_exclusive);
      },
    },
    filterName: {
      handler(val) {
        //console.log("filterName " + val);
        this.casesTable = this.cases
          // .map((item) => {
          //   //console.log(item.updatedAt.toString());
          //   //console.log(item);
          //   let _package = {
          //     text: "",
          //     value: "",
          //     price: 0,
          //     packageStatus: "None",
          //   };
          //   let selectPackage = "";
          //   if (typeof item.package !== "undefined") {
          //     _package = item.package;
          //   }

          //   if (typeof _package.text !== "undefined") {
          //     selectPackage = _package.text;
          //   }

          //   // if (typeof item.package.text !== "undefined") {
          //   //   selectPackage = item.package.text;
          //   // }
          //   //console.log(_package);

          //   let status = "Draft";
          //   if (typeof item.caseStatus !== "undefined") {
          //     status = item.caseStatus.stateStatus;
          //   }

          //   let shippingInformation =
          //     item.shippingInformation || this.shippingInformation;

          //   return {
          //     updatedAt: item.updatedAt.toString().substring(0, 10),
          //     id: item._id,
          //     caseNo: item.caseNo,
          //     sequence: item.sequence,
          //     runNo: item.runNo,
          //     doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
          //     feedback: "",
          //     selectPackage: selectPackage,
          //     patient_name: item.caseDetail.patient_name,
          //     shippingInformation: shippingInformation,
          //     status: status,
          //     _package: _package,
          //   };
          // })
          .filter((item) => {
            console.log(item);
            console.log(val);
            return (
              item.doctor_name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
              item.caseNo.indexOf(val) > -1
            );
          });
      },
    },
    cases: {
      handler(val) {
        //console.log(val);
        if (typeof val === "undefined") {
          this.cases = [];
        }
        this.prepareData();
      },
    },
  },
};
</script>
<style scope>
/* table {
  width: 100%;
}

table td,
table th {
  border: 1px solid;
} */
th {
  text-align: center !important;
}
</style>
